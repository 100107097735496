<template>
  <div class="me-search-wap">
    <div class="me-search-wap__header">
      <i class="me-icon-back" @click="$router.go(-1)"></i>
      <van-search v-model="keyword" placeholder="" autofocus :clearable="false" @search="handlerGetSearchData"
        right-icon="" left-icon="" class="me-search-wap__input">
        <i class="me-icon-search searchIcon" slot="right-icon" @click="handlerGetSearchData"></i>
      </van-search>

      <!-- <div class="me-search-wap__header--search">        
        <input
          type="text"
          v-model="keyword"
          v-focus="true"
          @keyup.enter.native="handlerGetSearchData"
        />
       
      </div> -->
    </div>
    <div class="me-search-wap__content">
      <template v-for="item in Object.keys(list)">
        <template v-if="list[item].length">
          <!-- <listTemp :list="list[item]" :type="item" :key="list[item].type" :count="counts[item]" /> -->
          <listTemp :list="list[item]" :type="item" :key="item"/>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { getSearchData, getSearchDataV5 } from "@/api/webApi";
import { get } from "@/utils/cache";
import listTemp from "@/components/business/h5/list-item";
export default {
  name: "search",
  components: {
    listTemp
  },
  data() {
    return {
      keyword: "",
      list: {},
      counts: {}
    };
  },
  watch: {
    keyword: {
      handler(newly) {
        if (newly) {
          //   this.handlerGetSearchData();
        }
        this.$store.dispatch("commitKeyword", newly);
      },
      deep: true
    }
  },
  created() { },
  methods: {
    async handlerGetSearchData() {
      const params = {
        language: get("langCode") || "1",
        userId: this.$store.state.User.userinfo.id || "",
        country: this.$store.state.countryId || "",
        categoryId: this.$store.state.categoryId || "",
        searchTerms: this.keyword
      };
      const resultV5 = await getSearchDataV5(params).catch(e => { });
      if(resultV5 && resultV5.data){
        this.list = resultV5.data.data
        console.log(this.list)
      }
    },
    formartActionType(type) {
      let _type = 1;
      switch (type) {
        case "serviceJob":
          _type = 1;
          break;
        case "user":
          _type = 3;
          break;
        case "group":
          _type = 4;
          break;
        case "enterprise":
          _type = 5;
          break;
      }
      return _type;
    }
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

.searchIcon{
  font-size 24px
}
+b(me-search-wap) {
  background: #ffffff;
  padding-top: 60px;
  +e(input){
    background none !important
    flex 1
  }

  +e(header) {
    background: #33cc66;
    height: 54px;
    width: 100%;
    padding: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    position: fixed;
    z-index: 3000;
    top: 0;
    left: 0;

    .me-icon-back {
      color: #ffffff;
      font-size: 24px;
      margin-left: 0.9rem;
    }

    +m(search) {
      flex: 1;
      background: #ffffff;
      border-radius: 0.3rem;
      margin: 0 0.9rem;
      height: 30px;
      display: flex;
      flex-flow: row;
      align-items: center;

      i {
        margin: 0 0.25rem;
        color: #666666;
        font-size: 26px;
      }

      input {
        flex: 1;
        border: 0;
        color: #666666;
        font-size: 13px;
        text-indent: 0.5rem;
      }
    }
  }
}
</style>